import React from "react";
import Slider from "react-slick";
import "../css/testimonial.css";

class ReactSlickDemo extends React.Component {
    render() {
        var settings = {
            dots: true
        };
        return (
            <div id="testimonial">
                <div className="container">
                <h2 className="abt-tst-thghts">Thoughts about Native Circle</h2>
                    <Slider {...settings}>
                        <div>
                            <p className="NC-testimonail-text">" I have watched many of your  videos, every video stands different and unique. Full of
                Information, great stalwarts, useful advise from them and the Ideas shared by them
                provides farmer&#39;s as well as a new entrant a ray of  hope, solid confidence and above
                all motivates them in a great way.
                
                I am very thankful to  you &amp;  also thank you whole heartedly  on behalf of  many more
                souls who would have thanked you in their hearts.
                
                May this world have many more of your tribe. Wishing one and all at native Circle
                success and Happiness in your Endeavour... "</p>

                <p className="clientName">- Sampath Kumar</p>
                        </div>
                        <div>
                            <p className="NC-testimonail-text">" This unique approach of identifying veteran farmers, profiling them, conducting
                workshops  within their premises is a unique way of transforming our agriculture field.
                These initiatives should be supported by both private and state bodies to strengthen
                this initiative further.
                I have attended a dozen of workshops and benefitted from each one of them. I am a
                practicing organic farmer now.. "</p>

                <p className="clientName">- G Kiran Kumar</p>
                        </div>
                        <div>
                            <p className="NC-testimonail-text">" I have heard that Native Circle works closely with farmers all across Karnataka, it is a
                 commendable feat in itself, surely all the farmers will join hands with Native Circle will
                 benefit from it in one or other way...
                 Kadidalu Dayanand, agriculturist , Teerthahalli
                 
                 Testimonials: (Personal care Products)
                 Brought body wash powder along with tooth powder, I am a happy customer "</p>
                 <p className="clientName">- Chandru SH</p>
                        </div>
                        <div>
                            <p className="NC-testimonail-text">" I happened to come across Native Circle products when I visited GoNative Cafe with a
                friend! I honestly bought it on a whim because I fell in love with the packaging. But the
                product was so good I have visited them thrice already because I have now been
                gifting
                them to friends and family! I am in love with the activated charcoal face pack and the
                pigmentation cure fack pack! I have an ultra sensitive skin and somehow they dont
                leave my skin dry or itchy after usage and that is saying something. I would certainly
                continue buying more and gifting them across! "</p>
                <p className="clientName">- Charanya Venkatesh, Bengaluru</p>
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}
export default ReactSlickDemo;
