import React from "react";
import Slider from "react-slick";
import "../css/testimonial.css";

class ReactSlickDemo1 extends React.Component {
    render() {
        var settings = {
            dots: true
        };
        return (
            <div id="testimonial1">
                <div className="container">
                    <h2 className="abt-tst-thghts1 NC-about-us-head-advisor">Our <span class="NC-awards-head-span">Advisors</span></h2>
                    <Slider {...settings}>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards flt-lft">
                                <p className="awardDesc-nws2">
                                    Prof. Rajaram Hegde
                                    Professor & Head (Retd)
                                    Dept. of Archaeology,
                                    Kuvempu University, Shankaraghatta

                </p>
                                <p className="awardDesc-nws2">
                                Specializes in Archaeology and  study of local culture.   Prof. Hegde has served as a prominent archaeologist and as a research guide.  He has worked closely with the  Ecole Pratique des Hautes Etudes, Paris and collaborated with the Department of Comparative Science of Cultures Ghent University, Belgium during his service. 

He has written 22 books, published over 100 research papers and articles.   Currently, he is engaged in the study of heritage & local cultures... 

                </p>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center flt-lft">
                                <img
                                    className="NC-wwb-man"
                                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1591359754/Dr_Rajaram_Hegde_y6epi8.png"
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards flt-lft">
                                <p className="awardDesc-nws2">
                                    Prof. R. Vasudeva
                                    Professor (Forest Biology)
                                    College of Forestry, SIRSI 581 401
                                    University of Agricultural Sciences, Dharwad

                </p>
                                <p className="awardDesc-nws2">
                                    Specializes in Forest Ecology, Tree Improvement, Conservation Genetics, Rural Livelihood Enhancement. He has published over 120 papers in his genre and has a global patent to his credit. He has developed  Forest Gene Bank for three endangered species and Successfully recovered a critically endangered tree species from the verge of extinction.
                </p>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center flt-lft">
                                <img
                                    className="NC-wwb-man"
                                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1591359754/Prof_Vasudeva_R_small_apuyug.png"
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards flt-lft">
                                <p className="awardDesc-nws2">
                                    Dr. M. Vasundhara,
                                    Professor and Head (Retd.)
                                    Dept. of Horticulture,
                                    UAS, GKVK, Bengaluru-65


                </p>
                                <p className="awardDesc-nws2">
                                    Specializes in Medicinal and Aromatic crops. She is involved in establishing and carrying out research on M& AP crops, for over 35 years, in collection, production, crop improvement, developing POP, organic cultivation, value addition and quality analysis.
                                    A recipient of Meritorious Scientist by EOAI and Distinguished Women Scientist from UAS Alumni association (2004). Dr.  Vasundhara has published over 150 research papers, 50 popular scientific articles and 49 books, Manual, brochures..
                                    
                                    She has been responsible for developing a variety AISIRI in Medicinal Coleus forskolii which is tolerant to nematodes, bacterial wilt and is a high yielder. She has also developed a variety of Stevia (GKVK1) which has higher stevioside and rebaudioside content.
                </p>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center flt-lft">
                                <img
                                    className="NC-wwb-man"
                                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1591359751/Dr_Vasundhara_z2pczc.png"
                                />
                            </div>

                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}
export default ReactSlickDemo1;
