import React from "react";
import Slider from "react-slick";
import "../css/testimonial.css";

class ReactSlickDemo1 extends React.Component {
  render() {
    var settings = {
      dots: true
    };
    return (
      <div id="testimonial1">
        <div className="container">
          <h2 className="abt-tst-thghts1 NC-about-us-head-awards">Our <span class="NC-advisory-head-span">Awards</span></h2>
          <Slider {...settings}>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center flt-lft">
                <img
                  className="NC-wwb-man"
                  src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1584446261/PhaseII/Forbes-30U30_lvji5o.png"
                />
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards flt-lft">
                <p className="awardDesc-nws2">
                  'The initiative to watch out for in 2020' by Forbes India
                </p>
                <p className="awardDesc-nws2">
                  Native Circle was declared as 'the initiative to watch out for in 2020'  by the Forbes India magazine's initiative 'The 30Under30'
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center flt-lft">
                <img
                  className="NC-wwb-man"
                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1568105837/Native%20Circle/unnati-logo-min.png"
                />
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards flt-lft">
                <p className="awardDesc-nws2">
                  Native Circle was declared a winner of 'Unnati' 2019 - a
                  scheme by Karnataka Government developed for offering
                  financial assistance to candidates and startups with
                  innovative business ideas.
                </p>
                <p className="awardDesc-nws2">
                  Native Circle was awarded on 31st may 2019 for its project -
                  "Emerging technology to empower farmers and create an
                  economically viable organic society", competing with more than
                  100 contestants.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export default ReactSlickDemo1;
