import React from "react";
import Slider from "react-slick";
import "../css/logocarousel.css";

class LogoCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            associations: ['https://res.cloudinary.com/dktnqgcmq/image/upload/v1595680970/Organic_Mandya_zs03kq.png', 'https://res.cloudinary.com/dktnqgcmq/image/upload/v1595680971/The_Organic_World_ojiqih.png', 'https://res.cloudinary.com/dktnqgcmq/image/upload/v1595680970/Simpli_namdhari_s_dycb51.png']
        }
    }
    componentDidMount() {
        let dynamicAssociations = this.props.associations
        if (dynamicAssociations != null) {
            dynamicAssociations = dynamicAssociations.map(o => o.node.acf.logo.source_url);
            this.setState({
                associations: dynamicAssociations
            })
        }
    }
    render() {
        var settings = {
            arrows: true,
            dots: false,
            // pauseOnHover: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        };
        return (
            <div className="container logoCarouselContainer">
                <Slider {...settings}>
                    {(this.state.associations).map((logo, i) => {
                        return (
                            <div class="col-sm-4 logoCarouselDiv">
                                <img className="logoCarouselImage" src={logo}
                                />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        );
    }
}

export default LogoCarousel;



