import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import LetsChat from "../components/letsChat";
import Testimonial from "../components/testimonials";
// import CountUp from 'react-countup';
import CountUp, { startAnimation } from 'react-countup';
// import './App.css';
import Testimonial1 from "../components/testimonials1";
import Logocarousel from "../components/logocarousel";
import Advisory from "../components/advisory";
import VisibilitySensor from 'react-visibility-sensor';

require("../css/about-us.css");

const style = {
  componentName: {},
  col: {},
  countup: {},
};

var tick = "https://res.cloudinary.com/djoztpm50/image/upload/v1565276218/tick_icon_d1yrmm-min_enpjqp.png";

class AboutUs extends React.Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  }

  render() {
    //ALL associations
    var associations = this.props.data.allWordpressWpAssociation.edges;
    return (

      <Layout>
        <div className="aboutUsBanner">
          <div className="col-sm-12">
            <h3 className="hitWorksHead">A consciously inclusive effort to grow as a single and strong community </h3>
            <div className="col-sm-6 aboutUsPoints">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276859/group-48-min_loy24v.png" />
                    <p className="hitWorksBanInfo">100% Organic</p>
                  </div>
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276799/group-25-min_ptu73k.png" />
                    <p className="hitWorksBanInfo">Transparent pricing</p>
                  </div>
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276942/group-27-min_tetdlj.png" />
                    <p className="hitWorksBanInfo">Value for money</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container aboutUs NC-vision">
          <div className="col-md-12 col-xs-12 cd-sm-12">

            {/* 
new */}
            <h3 className="NC-vision-title NC-about-us-head-story">
              <span className="NC-vision-span">Our</span> Story{" "}
              {/* <span className="NC-vision-span">&</span> mission */}
            </h3>
            <div className="NC-vision-desc">
              <p className="">
                Native circle is a conscious effort to go back to the original values and traditions which once
                shaped the value system of India.  Espousing the natural way of life and living, we aim to
                integrate dispersed expertise, successful practices and its own experimental results under
                one roof to develop an executable model.  Awareness, education, implementation of
                concepts and successful models along with value-addition are our areas of focus.
                In the process, Native Circle assists farmers in the entire organic farming chain.  By forming
                clusters we aim to educate, train and assist farmers through different programs, workshops
                and also spread the message through documentation, and value addition activities.
                An array of value added products – consumables and personal care (in edible form)-
                products are developed and offered to the consumers in the purest form…
                The B2B wing of Native circle offers Organic (and natural) stuff to Business houses, the
                hospitality chain and other business establishments across India and various other
                countries.
          </p>
              <p className="">
                To enable producer to consumer, farm to table network that supports
                both the ends in a transparent manner.
          </p>
            </div>

            {/* new endds here */}

            <h3 className="NC-vision-title NC-about-us-head-vision">
              <span className="NC-vision-span">Our</span> Vision{" "}
              <span className="NC-vision-span">&</span> mission
        </h3>
            <div className="NC-vision-desc">
              <p className="">
                To evolve a community of producers and consumers that revolves
                around natural and organic ecosystem.
          </p>
              <p className="">
                To enable producer to consumer, farm to table network that supports
                both the ends in a transparent manner.
          </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-xs-12">
            <div className="row">
              <div className="col-sm-4 col-md-4 col-xs-12">
                <div className="NC-vision-imagetext">
                  <div className="NC-vision-image">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276131/group-28_rn5qxw-min_uupfe1.png" />
                    <p className="NC-vision-text">Transparent ecosystem</p>
                  </div>
                  <div className="NC-vision-arrow">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276720/group-10_aygqgo-min_ajjabn.png" />
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-xs-12">
                <div className="NC-vision-imagetext">
                  <div className="NC-vision-image">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565275813/group-29_zlo30r-min_zxkg7u.png" />
                    <p className="NC-vision-text">Organic farming</p>
                  </div>
                  <div className="NC-vision-arrow">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276720/group-10_aygqgo-min_ajjabn.png" />
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-xs-12">
                <div className="NC-vision-imagetext">
                  <div className="NC-vision-image">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276090/group-30_v2ba2e-min_nzyocq.png" />
                    <p className="NC-vision-text">Updated technology</p>
                  </div>
                  {/* <div className="NC-vision-arrow">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551180511/Native%20Circle/group-10_aygqgo.png" />
                  </div> */}
                </div>
              </div>
              {/* <div className="col-sm-3 col-md-3 col-xs-12">
                <div className="NC-vision-imagetext">
                  <div className="NC-vision-image">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551179794/Native%20Circle/group-31_wipkrb.png" />
                    <p className="NC-vision-text">Customer</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <Testimonial1 />

        {/*<div className="col-sm-12 ourAwardsdiv" >
        <div className="container ">
          <div className="row">
            <div className="NC-wwb-text col-md-12 col-xs-12 col-sm-12">
              <h3 className="NC-whoWeAre-title">
                Our <span className="NC-whoWeAre-span">Awards</span>{" "}
              </h3>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center">
              <img
                className="NC-wwb-man"
                src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276307/unnati-logo-min_vhefo1.png"
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 NC-text-center ourAwards">
              
             <p className="awardDesc">
             Native Circle was declared a winner of 'Unnati' 2019 - a scheme by  Karnataka Government developed for offering financial assistance to candidates and startups with innovative business ideas. 
             </p>
             <p className="awardDesc">
             Native Circle was awarded on 31st may 2019 for its project - "Emerging technology to empower farmers and create an economically viable organic society", competing with more than 100 contestants.
             </p>
            </div>
           
          </div>
        </div>
        </div> */}


        <div className="col-sm-12 pad0 HowitworksMainDiv-nws">
          <div className="container pad0">
            <div className="col-sm-12 pad0">
              <h3 class="NC-whoWeAre-title NC-about-us-head-success">Our <span class="NC-whoWeAre-span">Success</span> </h3>
              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  {/* <p className="numb-abts-us-nw"><CountUp start={0} end={15}/></p> */}


                  <div className={style.componentName}>
                    {/* <h2 style={{ fontSize: '40em' }}>Heading</h2> */}
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                      top:
                        10
                    }} delayedCall>
                      <p className="numb-abts-us-nw"><CountUp className={style.countup} start={0} end={this.state.didViewCountUp ? 30 : 0}
                        duration={3} /></p>
                    </VisibilitySensor>
                  </div>

                  <p className="txt-abts-us-nw-frm">Number of farmers profiled </p>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className={style.componentName}>
                    {/* <h2 style={{ fontSize: '40em' }}>Heading</h2> */}
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                      top:
                        10
                    }} delayedCall>
                      <p className="numb-abts-us-nw"><CountUp className={style.countup} start={0} end={this.state.didViewCountUp ? 24 : 0}
                        suffix="K" duration={3} /></p>
                    </VisibilitySensor>
                  </div>
                  <p className="txt-abts-us-nw-frm">Number of farmers on board </p>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className={style.componentName}>
                    {/* <h2 style={{ fontSize: '40em' }}>Heading</h2> */}
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                      top:
                        10
                    }} delayedCall>
                      <p className="numb-abts-us-nw"><CountUp className={style.countup} start={0} end={this.state.didViewCountUp ? 60 : 0}
                        duration={3} /></p>
                    </VisibilitySensor>
                  </div>
                  <p className="txt-abts-us-nw-frm">Number of Workshops </p>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 NC-text-center">
                  <div className={style.componentName}>
                    {/* <h2 style={{ fontSize: '40em' }}>Heading</h2> */}
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                      top:
                        10
                    }} delayedCall>
                      <p className="numb-abts-us-nw"><CountUp className={style.countup} start={0} end={this.state.didViewCountUp ? 45 : 0}
                        duration={3} /></p>
                    </VisibilitySensor>
                  </div>
                  <p className="txt-abts-us-nw-frm">Number of Natural and Organic Products  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/*  <div className="aboutUsMainDiv">
          <div className="container pad0">
            <div className="col-sm-12 pad0 connectFarmerMain">
              <h5 className="connectFarmerHead">
                Native Circle {" "}
                <span className="connectFarmerHeadSpan">Timeline</span>
              </h5>
			  
			   <img id ="time-line-desc" src="https://res.cloudinary.com/djoztpm50/image/upload/v1565605843/pd20-280441-chim-02_copy-min_zeahk3.png"/>
			   
			   
              <div className="row">
                

                

                <div class="timeline-new" id ="time-line-mobile">
  <div class="container-new left-new">
    <div class="content-new">
      <h2 className="timeline-head">2016 - THE INCEPTION</h2>
 
  <div className="media">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Research and Documentation
                    </p>
                        </div>
                      </div>
 
  <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Awareness and education
                    </p>
                        </div>
                      </div>
 
  <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Data Collection
                    </p>
                        </div>
                      </div>
    </div>
  </div>
  <div class="container-new right-new">
    <div class="content-new">
      <h2 className="timeline-head">2017 - EVOLUTION</h2>
        <div className="media">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Workshop & Training
                    </p>
                        </div>
                      </div>
 
  <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Cluster Formation
                    </p>
                        </div>
                      </div>
 
 <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Farmer Support System
                    </p>
                        </div>
                      </div>
 
 <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Leader profiling and documentation
                    </p>
                        </div>
                      </div>
    </div>
  </div>
  <div class="container-new left-new">
    <div class="content-new">
      <h2 className="timeline-head">2018 - EXPANSION</h2>
     <div className="media">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Native Circle Social
                    </p>
                        </div>
                      </div>

                      <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Native Circle Platform
                    </p>
                        </div>
                      </div>

                      <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Consumer Connect
                    </p>
                        </div>
                      </div>

                      <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Farm to table initiative
                    </p>
                        </div>
                      </div>
    </div>
  </div>
  <div class="container-new right-new">
    <div class="content-new">
      <h2 className="timeline-head">2019 - ADDITION</h2>
 
  <div className="media">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Value Addition
                    </p>
                        </div>
                      </div>
 
 <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Seeds Conservation
                    </p>
                        </div>
                      </div>
 
  <div className="media mediaSec">
                        <div className="media-left">
                          <img
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                            className="media-object"
                          />
                        </div>
                        <div className="media-body">
                          <p className="farmerPointList">
                            Plant speices propagation
                    </p>
                        </div>
                      </div>
    </div>
  </div>
</div>   

                    
                  

                 
              </div>
            </div>
          </div>
        </div> */}

        <Testimonial />

        {/* <div className="row NC-testimonial responsive-aboutUs-quotes">
          <div className="container NC-testimonial-container">
            <div className="col-md-12 col-sm-12 col-xs-12 NC-text-center desktop-only">
              <img
                className="aboutUs-NC-quotes"
                src="https://res.cloudinary.com/djoztpm50/image/upload/v1551256019/Native%20Circle/path_tmkkbm.svg"
              />
              &nbsp;
          <img
                className="aboutUs-NC-quotes"
                src="https://res.cloudinary.com/djoztpm50/image/upload/v1551256019/Native%20Circle/path_tmkkbm.svg"
              />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 NC-text-center">
              <p className="NC-testimonail-text">
                "At home I serve the kind of food
            <br />
                <b>I know the story behind</b>”
          </p>
              <p className="NC-testimonial-by">Will Rogers</p>
            </div>
          </div>
        </div> */}

        <Advisory />

        <div className="container NC-what-we-believe">
          <div className="row">
            <div className="NC-wwb-text col-md-12 col-xs-12 col-sm-12">
              <h3 className="NC-about-us-head-believe NC-whoWeAre-title">
                What we <span className="NC-whoWeAre-span">believe</span>{" "}
              </h3>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 NC-text-center">
              <img
                className="NC-wwb-man"
                src="https://res.cloudinary.com/djoztpm50/image/upload/v1565275609/group-61-min_bfx8pj.jpg"
              />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 NC-text-center">
              <div className="NC-wwb-tile">
                <img
                  className="NC-wwb-icon"
                  // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                  src={tick}
                />
                <p className="NC-wwb-icontext">Transparency</p>
                <p className="NC-wwb-desc">
                  Ethical Harvesting. Responsible Value Addition. Reasonable market rates.
            </p>
              </div>
              <div className="NC-wwb-tile">
                <img
                  className="NC-wwb-icon"
                  // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                  src={tick}
                />
                <p className="NC-wwb-icontext">Ecofriendly </p>
                <p className="NC-wwb-desc">
                  Natural produces. Nature friendly. Degradable packaging.
            </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 NC-text-center">
              <div className="NC-wwb-tile">
                <img
                  className="NC-wwb-icon"
                  // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                  src={tick}
                />
                <p className="NC-wwb-icontext">Synergy</p>
                <p className="NC-wwb-desc">
                  Inclusive progress.Development for all. Symbiotic developments.
            </p>
              </div>
              <div className="NC-wwb-tile">
                <img
                  className="NC-wwb-icon"
                  // src="https://res.cloudinary.com/djoztpm50/image/upload/v1551267256/Native%20Circle/group-11.svg"
                  src={tick}
                />
                <p className="NC-wwb-icontext">Purity</p>
                <p className="NC-wwb-desc">
                  Pure produces. Traditional processes. Healthy food.
            </p>
              </div>
            </div>

          </div>
        </div>

        <div className="col-sm-12 pad0 NC-Partners" >
          <div className="container">
            <div className="col-sm-12 pad0">
              {/* <h5 className="connectFarmerHead">Workshop</h5> */}
              <h3 class="NC-Partners-title">We are <span class="NC-whoWeAre-span">associated with</span> </h3>
              <div className="row">
                <Logocarousel associations={associations} />
              </div>
            </div>
          </div>

        </div>
        {/* 
        <hr className="NC-separator" /> */}


        <LetsChat />
      </Layout>
    )
  }
}

export default AboutUs;

export const pageQuery190 = graphql`
query nativecircleworkshopInAboutUsj {
    allWordpressWpAssociation {
      edges {
        node {
          acf {
            logo {
              source_url
            }
          }
        }
      }
    }
  }
  
`;